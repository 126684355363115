// src/pages/ForgotPassword.tsx

import React, { useState } from 'react';
import { sendResetLink } from '../utils/api';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false); // New loading state
    const navigate = useNavigate();


    const handleForgotPassword = async () => {

        setLoading(true); // Set loading to true when login starts
        setError(''); // Clear any previous errors

        // Make API call
        try {
            const customUrl = 'https://price.ka.my';
            const companyName = 'Price at Ka.my';
            const response = await sendResetLink(email, customUrl, companyName);

            setSuccess(response.data.message);
            setError('');

            navigate('/login');

            setLoading(false); 

        } catch (err) {
            setLoading(false); 

            let errorMessage = 'Failed to send reset link. Please try again.';
            if (err instanceof Error) {
                errorMessage = err.message;
            } else if (typeof err === 'object' && err !== null && 'response' in err) {
                const response = (err as any).response;
                if (response && response.data && response.data.error) {
                    errorMessage = response.data.error;
                }
            }
            setError(errorMessage);
            setSuccess('');
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md">
                <h1 className="text-2xl font-semibold text-center mb-6">Forgot Password</h1>
                {success && <p className="text-green-600 text-center mb-4">{success}</p>}
                {error && <p className="text-red-600 text-center mb-4">{error}</p>}
                <h3>Your Registered Email Address</h3>
            <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full border border-gray-300 p-2 rounded mb-4 focus:outline-none focus:border-blue-500"
                />

                <button
                    onClick={handleForgotPassword}
                    className={`bg-blue-500 text-white p-3 w-full rounded transition duration-200 ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
                    disabled={loading} // Disable the button when loading
                >
                    {loading ? 'Processing...' : 'Send Reset Link'} {/* Change button text when loading */}
                </button>

                {/* <button
                    onClick={handleForgotPassword}
                    className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition-colors"
                >
                    Send Reset Link
                </button> */}

                <p className="mt-6 text-center text-sm text-gray-600">
                    Remembered your password?{' '}
                    <a href="/login" className="text-blue-500 hover:underline">
                        Login
                    </a>
                </p>
            </div>
        </div>
    );
};

export default ForgotPassword;

