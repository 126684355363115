import React, { useState } from 'react';
import { resetPassword } from '../utils/api'; // Import reset password API function
import { useNavigate } from 'react-router-dom';

const PasswordReset = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false); // New loading state
    const navigate = useNavigate();

    const handlePasswordReset = async () => {

        setLoading(true); // Set loading to true when login starts
        setError(''); // Clear any previous errors

        try {
            // Reset link token might be retrieved from query params or state if navigated from email link
            // const token = new URLSearchParams(window.location.search).get('token');
            const urlParts = window.location.pathname.split('/');
            const token = urlParts[2]; // Get the third part of the URL path

            // console.log('token: ',token);

            if (!token) {
                setError('Invalid or expired token.');
                setLoading(false);

                return;
            }

            // Validate passwords match
            if (password !== confirmPassword) {
                setError('Passwords do not match.');
                setLoading(false);

                return;
            }

            // API call to reset the password
            const response = await resetPassword(email, password, confirmPassword, token);
            setSuccess(response.data.message);
            setError('');

            navigate('/login');

            setLoading(false);
        } catch (err) {
            setLoading(false);

            setError('Password reset failed. Please try again, or WhatsApp +6011-12163808 for support.');
            setSuccess('');
        }
    };

    return (
        <div className="container mx-auto">
            <h1 className="text-center text-2xl font-semibold mb-4">Reset Password</h1>
            {success && <p className="text-green-500">{success}</p>}
            {error && <p className="text-red-500">{error}</p>}
            <h3>Your Registered Email Address</h3>

            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border p-2 w-full mb-2"
            />
            <h3>Your NEW Password</h3>

            <input
                type="password"
                placeholder="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border p-2 w-full mb-2"
            />
            <h3>Confirm Your NEW Password Again</h3>

            <input
                type="password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="border p-2 w-full mb-4"
            />

            <button
                onClick={handlePasswordReset}
                className={`bg-blue-500 text-white p-3 w-full rounded transition duration-200 ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
                disabled={loading} // Disable the button when loading
            >
                {loading ? 'Processing...' : 'Reset Password'} {/* Change button text when loading */}
            </button>
            
            {/* <button
                onClick={handlePasswordReset}
                className="bg-blue-500 text-white w-full py-2"
            >
                Reset Password
            </button> */}
        </div>
    );
};

export default PasswordReset;
