// pages/Register.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from '../utils/api';
import axios from 'axios';
import { setAuthToken } from '../utils/auth';

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [phone, setPhone] = useState(''); // Optional phone state
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // New loading state

    const handleRegister = async () => {


        setLoading(true); // Set loading to true when login starts
        setError(''); // Clear any previous errors

        // Check if name is provided
        if (!name.trim()) {
            setError("Please enter your name. Name cannot be empty!");
            setLoading(false);
            return;
        }

        // Check if phone is provided
        if (!phone.trim()) {
            setError("Please enter your phone number. Phone number cannot be empty!");
            setLoading(false);

            return;
        }

        // Check if passwords match
        if (password !== passwordConfirmation) {
            setError("Passwords do not match.");
            setLoading(false);
            return;
        }

        try {
            const customUrl = 'https://next.ka.my'; // Additional parameter
            const companyName = 'KA Price'; // Additional parameter

            const response = await register(name, email, password, passwordConfirmation, phone, customUrl, companyName);

            const { user, access_token } = response.data;

            if (access_token) {
                setAuthToken(access_token);
                localStorage.setItem('token', access_token);
                localStorage.setItem('user', JSON.stringify(user));  // Save user data here
            }

            navigate('/login');

        } catch (err) {
            setLoading(false);

            if (axios.isAxiosError(err) && err.response?.status === 422) {
                // Capture Laravel's validation error messages
                const validationErrors = err.response.data.errors;
                console.error("Validation errors:", validationErrors);
                setError('Registration failed. ' + JSON.stringify(validationErrors));
            } else {
                console.error("Registration error:", err);
                setError('Registration failed. Please check your details and try again.');
            }
        }
    };

    return (
        <div className="container mx-auto max-w-md p-6 shadow-lg rounded-md bg-white">
            <h1 className="text-2xl font-bold mb-4">Register</h1>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border p-2 mb-4 w-full"
            />
            <input
                type="text"
                placeholder="Phone (if you want VIP Price list)"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="border p-2 mb-4 w-full"
            />
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border p-2 mb-4 w-full"
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border p-2 mb-4 w-full"
            />
            <input
                type="password"
                placeholder="Confirm Password"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                className="border p-2 mb-4 w-full"
            />


            <button
                onClick={handleRegister}
                className={`bg-blue-500 text-white p-3 w-full rounded transition duration-200 ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
                disabled={loading} // Disable the button when loading
            >
                {loading ? 'Processing...' : 'Register'} {/* Change button text when loading */}
            </button>
            {/* <button onClick={handleRegister} className="bg-blue-500 text-white p-2 w-full rounded">
                Register
            </button> */}
        </div>
    );
};

export default Register;
