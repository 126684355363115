import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, fetchRolesPermissions } from '../utils/api'; // Ensure these functions are defined in your api.js

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // New loading state


    const handleLogin = async () => {

        setLoading(true); // Set loading to true when login starts
        setError(''); // Clear any previous errors

        try {
            const response = await login(email, password);

            localStorage.setItem('token', response.data.user.remember_token);
            localStorage.setItem('user', JSON.stringify(response.data.user));

            const rolesPermissionsResponse = await fetchRolesPermissions();
            localStorage.setItem('rolesPermissions', JSON.stringify(rolesPermissionsResponse));

            navigate('/');
        } catch (err: any) {
            setLoading(false); // Set loading to true when login starts

            // Define a default error message
            let errorMessage = 'Login failed. Please check your credentials.';

            // Check if the error has a response and status
            if (err.response) {
                const status = err.response.status;

                // Map specific status codes to human-readable messages
                switch (status) {
                    case 401:
                        errorMessage = 'Unauthorized: Invalid username or password.';
                        break;
                    case 402:
                        errorMessage = 'Payment Required: Please contact support for assistance.';
                        break;
                    case 403:
                        errorMessage = 'Forbidden: You do not have permission to access this resource.';
                        break;
                    case 404:
                        errorMessage = 'Not Found: The server could not find the requested resource.';
                        break;
                    case 500:
                        errorMessage = 'Server Error: Something went wrong. Please try again later.';
                        break;
                    default:
                        errorMessage = `Error ${status}: ${err.response.statusText}`;
                        break;
                }
            } else if (err.message) {
                errorMessage = err.message;
            }

            setError(errorMessage);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-md rounded-lg p-8 w-full max-w-sm">
                <h1 className="text-2xl font-bold mb-6 text-center">Login</h1>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border border-gray-300 p-3 mb-4 w-full rounded"
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="border border-gray-300 p-3 mb-4 w-full rounded"
                    required
                />
                <button
                    onClick={handleLogin}
                    className={`bg-blue-500 text-white p-3 w-full rounded transition duration-200 ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
                    disabled={loading} // Disable the button when loading
                >
                    {loading ? 'Processing...' : 'Login'} {/* Change button text when loading */}
                </button>
                <p className="mt-4 text-center">
                    Don't have an account? <a href="/register" className="text-blue-500">Register</a>
                </p>
                <p className="mt-2 text-center">
                    <a href="/forgot-password" className="text-blue-500">Forgot Password?</a>
                </p>
            </div>
        </div>
    );
};

export default Login;
